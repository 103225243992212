<template>
  <div id="box">
    <!-- 导航栏 -->
    <div class="menu">
      <div class="menu_head">
        <!-- 大唐杯logo -->
        <img src="./Simulation/logo.png" alt="">
        <span class="menu_box">
          <el-button type="primary" round class="but1" @click="head('联系我们')">免费试用联系我们</el-button>
          <el-button type="text" class="but2" @click="head('仿真登录')">仿真登录</el-button>
          <span style="color: #fff;">|</span>
          <el-button type="text" class="but2" @click="head('学唐登录')">学唐登录</el-button>
          <span style="color: #fff;">|</span>
          <el-button type="text" class="but3" @click="head('回到首页')">回到首页</el-button>
        </span>
      </div>
    </div>

    <!-- 视频内容 -->
    <div class="content">
      <div class="video">
        <video style="object-fit: fill; width: 100%; height: 100%" id="myVideo" controls controlsList="nodownload" muted
          disablePictureInPicture="true" oncontextmenu="return false" :poster="poster" ref="videoPlayer"
          :src="video"></video>
        <img v-show="video_show" @click="unfold('list2')" class="video_L" src="./Simulation/list2.png" alt="">

        <div class="video_drawer">
          <div style="display: flex; clear: auto;">
            <transition name="el-fade-in-linear">
              <div v-show="drawer" class="transition-box">
                <img @click="unfold('list')" class="video_D" src="./Simulation/list.jpg" alt="">
                <div>
                  <div class="introduce1" @click="introduce('1')">
                    <span>
                      虚拟仿真练习与实操
                    </span>
                    <span>
                      实训平台模块介绍
                    </span>
                  </div>

                  <div style="margin-top: .1rem;">
                    <el-collapse-transition>
                      <div v-show="Collapse1">
                        <ul class="ul_box">
                          <li :class="item_title == item.title ? 'divClass' : ''" v-for="(item, index) in newList"
                            :key="index" @click="video_li(item.title, '1')">{{
                              item.title
                            }}</li>
                        </ul>
                      </div>
                    </el-collapse-transition>
                  </div>
                </div>
                <div>
                  <div class="introduce2" @click="introduce('2')">
                    <span>
                      第十届“大唐杯”
                    </span>
                    <span>
                      赛前培训直播回放
                    </span>
                  </div>

                  <div style="margin-top: .1rem;height: 2.4rem;">
                    <el-collapse-transition>
                      <div v-show="Collapse2">
                        <ul class="ul_box">
                          <li :class="item_title == item.title ? 'divClass' : ''" v-for="(item, index) in newList2"
                            :key="index" @click="video_li(item.title, '2')">{{
                              item.title }}</li>
                        </ul>
                      </div>
                    </el-collapse-transition>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="video_introduce">
        <p class="video_title">{{ title }} <span v-show="disp">—— ——</span> <span>{{ modules }}</span></p>
        <p class="video_p">视频简介</p>
        <p class="video_text">{{ brief }}</p>
      </div>
    </div>

    <!-- 介绍内容 -->
    <div class="introduce">
      <img class="introduce_img" src="./Simulation/ction.jpg" alt="">
      <div class="introduce_content">
        <p class="introduce_title">{{ title }}</p>
        <br>
        <div class="introduce_text">
          {{ text }}
        </div>
      </div>
    </div>

    <!-- 版权所有 -->
    <div class="copyright">
      <div class="copyright_hurdle">
        <ul>
          <li @click="navigation('大赛首页')">大赛首页</li>
          <li>|</li>
          <li @click="navigation('赛事介绍')">赛事介绍</li>
          <li>|</li>
          <li @click="navigation('官方文件')">官方文件</li>
          <li>|</li>
          <li @click="navigation('赛区规划')">赛区规划</li>
          <li>|</li>
          <li @click="navigation('大赛培训')">大赛培训</li>
          <li>|</li>
          <li @click="navigation('联系我们')">联系我们</li>
        </ul>
      </div>
      <p class="copyright_p">Copyright 2022,中信科移动</p>
    </div>

    <!-- 二维码固定 -->
    <div data-v-73a23e1a="" data-v-3ec6243f="" class="com-contact-wrapper anmiated bounceIn">
      <div data-v-73a23e1a="" class="com-contact-btn com-contact-btn-pc" @click="back_top">
        <span>回到</span><br>
        <span>顶部</span>
      </div>
    </div>

  </div>
</template>
  
<script>
// @ is an alias to /src
import Footer from "@/components/Footer/Footer.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import $ from "jquery";
import { getLogin } from "api/apis.js";
import { head } from "lodash";

export default {
  name: "Home",
  components: {
    Footer,
    Bottom,
  },
  data() {
    return {
      poster: require("./Simulation/video.png"),
      tabPosition: "left",
      newList: "",
      newList2: "",
      modules: "",
      brief: "",
      video: "",
      video_show: true,
      drawer: false,
      Collapse1: true,
      Collapse2: false,
      disp: true,
      item_title: "",
      title: '虚拟仿真练习与实操实训平台模块介绍',
      text: "虚拟仿真练习与实操实训平台是中信科移动培训中心根据新一代信息通信等专业相关课程，综合行业规范、贯穿5G等新一代信息通信技术教学重难点实现施工场景仿真模拟，通过人机交互式操作、成果实现智能考评等多项功能于一体的综合性虚拟仿真教学实训系统。系统根据通信及其相关专业相关课程的核心重点内容，针对5G网络建设、5G应用场景部署等实际工作场景，进行了模块化虚拟操作设计，为教学实训提供最接近真实工作环境的虚拟实训平台。",
    };
  },
  mounted() {
    this.Acquire_introduce();
    this.Acquire_broadcast();

  },
  methods: {
    Acquire_introduce() {
      let dataobj = new FormData();
      dataobj.append("label", "视频简介"); //文件
      getLogin.getFile(dataobj).then((res) => {
        this.newList = JSON.parse(res.data);
        this.modules = this.newList[0].title;
        this.brief = JSON.parse(this.newList[0].accessory)[0].memo;
        this.video = JSON.parse(this.newList[0].accessory)[0].url;

      });
    },
    Acquire_broadcast() {
      let dataobj = new FormData();
      dataobj.append("label", "直播回放"); //文件
      getLogin.getFile(dataobj).then((res) => {
        this.newList2 = JSON.parse(res.data);
      });
    },
    video_li(val, D) {
      this.item_title = val
      this.disp = true
      if (D == '1') {
        this.newList.map((item, index) => {
          if (item.title == val) {
            this.modules = item.title;
            this.brief = JSON.parse(item.accessory)[0].memo;
            this.video = JSON.parse(item.accessory)[0].url;
          }
        });
        this.poster = require("./Simulation/video.png");
        this.drawer = false;
        this.video_show = true;
        this.$nextTick(() => {
          this.$refs.videoPlayer.muted = false;
          this.$refs.videoPlayer.play();
        })
      } else if (D == '2') {
        this.newList2.map((item, index) => {
          if (item.title == val) {
            this.modules = item.title;
            this.brief = JSON.parse(item.accessory)[0].memo;
            this.video = JSON.parse(item.accessory)[0].url;
          }
        });
        this.poster = require("./Simulation/video2.png");
        this.drawer = false;
        this.video_show = true;
        this.$nextTick(() => {
          this.$refs.videoPlayer.muted = false;
          this.$refs.videoPlayer.play();
        })



      }
    },
    unfold(val) {
      if (val == 'list2') {
        this.drawer = true
        this.video_show = false
      } else if (val == 'list') {
        this.drawer = false
        this.video_show = true
      }
    },
    introduce(val) {
      this.disp = false
      if (val == '1') {
        this.modules = "";
        this.Collapse1 = !this.Collapse1
        this.title = '虚拟仿真练习与实操实训平台模块介绍'
        this.text = '虚拟仿真练习与实操实训平台是中信科移动培训中心根据新一代信息通信等专业相关课程，综合行业规范、贯穿5G等新-代信息通信技术教学重难点实现施工场景仿真模拟，通过人机交互式操作、成果实现智能考评等多项功能于一体的综合性虚拟仿真教学实训系统。系统根据通信及其相关专业相关课程的核心重点内容，针对5G网络建设、5G应用场景部署等实际工作场景，进行了模块化虚拟操作设计，为教学实训提供最接近真实工作环境的虚拟实训平台。'
      } else if (val == '2') {
        this.modules = "";
        this.Collapse2 = !this.Collapse2
        this.title = '第十届“大唐杯”赛前培训直播回放'
        this.text = '大唐杯全国大学生新一代信息通信技术大赛以推广信息通信领域前沿技术、协同高校学科建设、推动学生创新创业为目的，旨在激发新时代信息技术人才发展新动能，提升学生5G技术理论与工程实践创新能力，匹配工科学生毕业要求、现场工程师岗位能力要求及卓越工程师培养要求，推进高校“双一流”及“双高”建设，促进电子信息类相关专业教学内容和教学方法的改革创新，推动5G+垂直产业应用创新，助力电子信息领域学生未来创业，促进高校信息通信技术的研究和成果转化，助力电子信息类专业高质量发展。'
      }
    },
    // 回到首页
    head(val) {
      if (val == '联系我们') {
        this.$router.push("/Contact");
      } else if (val == '仿真登录') {
        window.open("https://www.dtxiaotangren.com/", "_blank");
      } else if (val == '学唐登录') {
        window.open("http://dtmobile.yunxuetang.cn/login.htm", "_blank");
      } else if (val == '回到首页') {
        this.$router.push("/HomePage");
      }
    },
    // 底部导航
    navigation(val) {
      if (val == '大赛首页') {
        this.$router.push("/HomePage");
      } else if (val == '赛事介绍') {
        this.$router.push("/Brief");
      } else if (val == '官方文件') {
        this.$router.push("/File");
      } else if (val == '赛区规划') {
        this.$router.push("/fingerpost");
      } else if (val == '大赛培训') {
        this.$router.push("/Train");
      } else if (val == '联系我们') {
        this.$router.push("/Contact");
      }
    },
    back_top() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    }
  },
};
</script>
  
<style scoped lang="scss">
.divClass {
  color: #2442B2 !important;
  font-weight: 600
}

@import "./Simulation.scss";
</style>